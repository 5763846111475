export const cognito = {
  cognitoUserPoolId: 'eu-west-1_DUusfeHDF',
  cognitoIdentityPoolId: 'eu-west-1:771ca782-d686-4bff-815a-26c1f3890922',
  cognitoAppClientId: '2oajk616ed6ttfdvcijbjc34qu',
  cognitoUserPoolDomain: 'theexeter-member-uat.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
};

export const googleAnalyticsTag = 'G-PD0M2WX1H9';

export default {};
